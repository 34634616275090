const Boxes = {
    box1: function () {
        var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-6, 5, 6,-7],keepaspectratio: true, pan:{enabled:false},zoom:{enabled:false}, axis:false, ticks:true, grid:true, showCopyright:false, showNavigation:false});
        brd1.options.layer['axis'] =1;
        brd1.options.layer['line'] =5;
        brd1.options.layer['curve'] =7;
        brd1.options.layer['circle'] =3;
        brd1.options.layer['point'] =12;
        brd1.options.layer['glider'] =11;
        brd1.options.layer['intersection']=6;
        var resize = function () {
              brd1.resizeContainer(brd1.containerObj.clientWidth, brd1.containerObj.clientHeight, true);
              brd1.fullUpdate();
              };
        window.onresize = resize;
        var ls = 1.0;
        var shft = 5.25;
        //var rad = brd1.create('slider',[[4,1.5],[6,1.5],[1,1,2]]);
		brd1.create('text', [-4, 4.5, '<b>Design an Airfoil using Joukowsky Transform</b>'], {fixed:true, fontSize:function(){return 28*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
        var ax1 = brd1.create('arrow',[[-0.2,0],[4.8,0]],{strokeColor:'grey', fixed:true});
        var ax2 = brd1.create('arrow',[[0,-0.2],[0,3.8]],{strokeColor:'grey', fixed:true});
        brd1.create('text',[4.6,0.2, '<i>x </i>'],{fontColor:'black',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}, fixed:true});
        brd1.create('text',[0.1,3.8,'<i>y</i>'],{fontColor:'black', fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}, fixed:true});
		brd1.create('arrow',[[-4.5,0],[-3.5, 0]],{strokeColor:'blue', fixed:true});
		brd1.create('arrow',[[-4.5,1],[-3.5, 1]],{strokeColor:'blue', fixed:true});
		brd1.create('arrow',[[-4.5,2],[-3.5, 2]],{strokeColor:'blue', fixed:true});
		brd1.create('arrow',[[-4.5,-1],[-3.5, -1]],{strokeColor:'blue', fixed:true});
		brd1.create('arrow',[[-4.5,-2],[-3.5, -2]],{strokeColor:'blue', fixed:true});
		brd1.create('arrow',[[-4.5,-3],[-3.5, -3]],{strokeColor:'blue', fixed:true});
		brd1.create('arrow',[[-4.5,-4],[-3.5, -4]],{strokeColor:'blue', fixed:true});
		brd1.create('arrow',[[-4.5,-5],[-3.5, -5]],{strokeColor:'blue', fixed:true});
		brd1.create('arrow',[[-4.5,-6],[-3.5, -6]],{strokeColor:'blue', fixed:true});
        brd1.create('arrow',[[-0.5,-shft],[4.5, -shft]],{strokeColor:'grey', fixed:true});
        brd1.create('arrow',[[0,-shft-0.5],[0,3.2-shft]],{strokeColor:'grey', fixed:true});
        brd1.create('text',[4.5,0.2-shft, '&zeta;'],{fontColor:'black',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}, fixed:true});
        brd1.create('text',[0.1,3.2-shft,'&eta;'],{fontColor:'black', fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}, fixed:true});
		brd1.create('text',[-5, -1.5,'v'],{fontColor:'black', fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}, fixed:true});
        var vert =brd1.create('line', [[0,0],[0.,5]], {visible:true, strokeWidth:0, dash:1});
        var horzt = brd1.create('line', [[0,0],[5., 0.]], {visible:true, strokeWidth:0, dash:1});
        var pcenter =brd1.create('intersection', [horzt,vert],{face:'circle', name:'C', size:3, strokeColor:'black', label:{CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)},offset:[-5,-20]}});
        var cyl = brd1.create('circle', [pcenter, 1],{visible:false});
        var tpp = brd1.create('intersection', [vert,cyl, 0],{visible:false, name:'', strokeColor:'black'});
        var rtp = brd1.create('intersection', [horzt,cyl, 0],{visible:false, name:'', strokeColor:'black'});
        var horz =brd1.create('line', [pcenter, rtp],{visible:false});
        var pout = brd1.create('glider',[-1.0, 0.0, horz],{face:'square', strokeColor:'black', name:'Radius', size:3, fillColor:'yellow', shadow:true, label:{CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)},offset:[-50, 0]}});
        var perp1 = brd1.create('perpendicularsegment',[pcenter, ax1],{strokeWidth:0.5, dash:1});
        var perp2 = brd1.create('perpendicularsegment',[pcenter, ax2], {strokeWidth:0.5, dash:1});
        var cyll =brd1.create('circle',[pcenter, pout],{strokeColor:'black', fillColor:'red', fillOpacity:0.25});
		var PtO=brd1.create('point', [0,0],{visible:false});
		var PtA =brd1.create('point', [1,0],{visible:false});
		brd1.create('segment', [PtO, pcenter], {strokeColor:'grey', strokeWidth:1});
		var ang=brd1.create('angle', [PtA, PtO, pcenter],{name:'&alpha;', radius:0.25,label:{CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
        var tppp = brd1.create('intersection', [vert,cyll, 0],{face:'triangleright',size:6, name:'Left&Right', strokeColor:'black', label:{CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
        var rtpp = brd1.create('intersection', [horzt,cyll, 0],{face:'triangleup', size:6, name:'Up&Down', strokeColor:'black', label:{CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
		//brd1.create('text', [-2, 4, 'Lift = 4&pi;&rho;av^2sin(&alpha;)'], {fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
		brd1.create('text', [-4, 3.75, function(){return 'Lift = 4&pi;&rho;av^2sin(&alpha;) = '+ (4*Math.PI*1.225*1*1*Math.abs(pcenter.X()-pout.X())*Math.sin(ang.Value())).toFixed(2) + ' N/m'}], {fixed:true, fontSize:function(){return 20*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
		brd1.create('text', [-4,3, function(){return '&alpha; = ' + (ang.Value()*180/Math.PI).toFixed(2) + '^o'}],{fixed:true, fontSize:function(){return 20*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'})
		brd1.create('text',[3,-1,'f(z) = z + 1/z'],{fontColor:'black', fontSize:function(){return Math.round(20*brd1.canvasWidth/500.)}, fixed:true, cssStyle:'fontFamily:Oswald;'});
        //var pcenter = brd1.createElement('point',[0.0,0.0],{face:'square', strokeColor:'black', name:'Center', size:3, fillColor:'yellow', shadow:true});
        //var pcent =brd1.create('glider',[0, 0, vert],{face:'square'});
        //var perp1 = brd1.create('perpendicularsegment',[pcenter, ax1],{strokeWidth:0.5, dash:1});
        //var perp2 = brd1.create('perpendicularsegment',[pcenter, ax2], {strokeWidth:0.5, dash:1});
        //var horz =brd1.create('line', [[0,function(){return pcenter.Y();}],[5.,function(){return pcenter.Y();}]], {visible:false});
        //var pout = brd1.create('glider',[1.0, 0.0, horz],{face:'square', strokeColor:'black', name:'Radius', size:3, fillColor:'yellow', shadow:true});
        //var pdraw = brd1.create('point',[function(){return pout.X();}, function(){return pcenter.Y();}],{face:'square', strokeColor:'black', name:'', size:0, fillColor:'yellow', shadow:true});
        //var cyl =brd1.create('circle', [[function(){return pcenter.X();},function(){return pcenter.Y();}], pout],{strokeColor:'black', strokeWidth:2, fillColor:'lightblue', fillOpacity:0.8});
        function x(t) {return pcenter.X()+Math.sqrt((pout.X()-pcenter.X())*(pout.X()-pcenter.X())+(pout.Y()-pcenter.Y())*(pout.Y()-pcenter.Y()))*Math.cos(t) + (pcenter.X()+Math.sqrt((pout.X()-pcenter.X())*(pout.X()-pcenter.X())+(pout.Y()-pcenter.Y())*(pout.Y()-pcenter.Y()))*Math.cos(t))/(Math.pow(pcenter.X()+ Math.sqrt((pout.X()-pcenter.X())*(pout.X()-pcenter.X())+(pout.Y()-pcenter.Y())*(pout.Y()-pcenter.Y()))*Math.cos(t),2)+Math.pow(pcenter.Y()+ Math.sqrt((pout.X()-pcenter.X())*(pout.X()-pcenter.X())+(pout.Y()-pcenter.Y())*(pout.Y()-pcenter.Y()))*Math.sin(t),2))}
        function y(t) {return -shft+pcenter.Y()+Math.sqrt((pout.X()-pcenter.X())*(pout.X()-pcenter.X())+(pout.Y()-pcenter.Y())*(pout.Y()-pcenter.Y()))*Math.sin(t) - (pcenter.Y()+Math.sqrt((pout.X()-pcenter.X())*(pout.X()-pcenter.X())+(pout.Y()-pcenter.Y())*(pout.Y()-pcenter.Y()))*Math.sin(t))/(Math.pow(pcenter.X()+ Math.sqrt((pout.X()-pcenter.X())*(pout.X()-pcenter.X())+(pout.Y()-pcenter.Y())*(pout.Y()-pcenter.Y()))*Math.cos(t),2)+Math.pow(pcenter.Y()+Math.sqrt((pout.X()-pcenter.X())*(pout.X()-pcenter.X())+(pout.Y()-pcenter.Y())*(pout.Y()-pcenter.Y()))*Math.sin(t),2))}
        brd1.createElement('curve', [x,y, 0.0, 2*Math.PI], {strokeColor:'grey', strokeWidth:2, fillColor:'lightblue', strokeColor:'black', fillOpacity:0.5});
    }
}

export default Boxes;
