<template>
  <div>
    <h3 ref="airfoil">
      Airfoil Theory
    </h3>
    <p>
      The airfoil theory concerns with the flow of a fluid around an airfoil &#8213; particularly with the aim of designing an airfoil geometry with desired lift and drag characteristics. The central to the airfoil theory is Joukowsky transformation (named after Russian mathematican Nikolai Joukowsky), which allows to map flow past a cylinder to a flow past an airfoil of any given shape.
    </p>
    <h3 ref="joukowsky">
      Joukowsky Transformation
    </h3>
    <p>
      Joukowsky considered an irrotational, inviscid, incompressible flow past a cylinder of radius \(a\). He showed that the flow around a cylinderical object can be mapped into the flow around another body by means of conformal mapping. Joukowsky proposed the following transformation
      $$\frac{\zeta}{c} = \frac{z}{c} + \frac{c}{z}$$
      where \(z = x +i y\) refers to a point in the original coordinate system while \(\zeta\) refers to the point in the transformed coordinate system.
      Subsituting
      $$z = r e^{i\theta} = r (\cos\theta + i\sin \theta)$$
      and
      $$z^{-1} = \frac{1}{r} e^{-i\theta} = \frac{1}{r}(\cos\theta - i\sin \theta)$$
      we obtain:
      $$ \xi+ i\eta= \bigg \lgroup \frac{r}{c}+ \frac{c}{r} \bigg \rgroup \cos\theta + i \bigg \lgroup \frac{c}{r} - \frac{r}{c} \bigg\rgroup \sin\theta $$
      Comparing the real parts on the two sides of the equation, we obtain:
      $$ \xi = \bigg \lgroup \frac{r}{c}+ \frac{c}{r} \bigg \rgroup \cos\theta  $$
      Comparing the imaginary parts of the equation, we obtain:
      $$\eta = \bigg \lgroup \frac{c}{r} - \frac{r}{c} \bigg\rgroup \sin\theta $$
      Thus, circle of radius a (i.e. \(r =a \)) in the z-plane maps to the following section in the transformed plane:
      $$ \bigg \lgroup \frac{\xi}{\frac{a}{c}+ \frac{c}{a}}\bigg \rgroup^2 + \bigg \lgroup \frac{\eta}{-\frac{a}{c}+ \frac{c}{a}} \bigg \rgroup^2= 1$$
      The above is the equation of an ellipse with semi-major axis \(=\frac{c}{a}+ \frac{a}{c}\) and semi-minor axis \(=\frac{c}{a} - \frac{a}{c}\).
    </p>
    <h3 ref="features">
      Features of Joukowsky Transformation
    </h3>
    <ul style="list-style-type:square;">
      <li>
        The transformation maps the cylinder with radius <i>a </i> into an ellipse with semi-major axis \(=\frac{c}{a}+ \frac{a}{c}\) and semi-minor axis \(=\frac{c}{a} - \frac{a}{c}\).
      </li>
      <li> For a cylinder with radius =c, the ellipse collapses into a flat plate.</li>
      <li> Upon moving the center of the cylinder along the x-axis, a symmetric airfoil is generated.</li>
      <li> Upon moving the center of the cylinder parallel the x-axis, a symmetric airfoil is generated.</li>
      <li> If the center of the cylinder is moved parallel to y-axis, a camber in the airfoil is introduced.</li>
    </ul>
    <br>
    <h3 ref="graphical_ilustration">
      MagicGraph: Graphical Illustration of Joukowsky Transformation
    </h3>
    <v-responsive
      :aspect-ratio="1"
    >
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>

<script>
import Boxes from './Boxes.js'
export default {
  name: 'Joukowsky',
  created: function () {
    const title = 'Classical Airfoil Theory';
    // Store mutations
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Airfoil Theory', img:'assets/number-1.svg', action: () => this.goto('airfoil')},
      {title: 'Joukowsky Transformation', img:'assets/number-2.svg', action: () => this.goto('joukowsky')},
      {title: 'Features Joukowsky Transformation', img:'assets/number-3.svg', action: () => this.goto('features')},
      {title: 'PlayGraph: Graphical Illustration', img:'assets/touch.svg', action: () => this.goto('graphical_ilustration')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  }
}
</script>

<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
